import { getCompanyIDAndProjectID, sortByName } from "../../helpers/utils";
import useQuery from "../../hooks/useQuery";
import React, { useEffect } from "react";
import { documentTypes, documentTypes_project_documentTypes, documentTypesVariables } from "__generated__/documentTypes";
import { Select } from "antd";
import { createDocumentTypeKey, HasDocTypeFields, HasDocTypeFieldsWithName } from "components/documents/documentUtils";
import GET_DOCUMENT_TYPES from "gql/getDocumentTypes.gql";
import { CustomType } from "__generated__/globalTypes";

interface DocumentTypeSelectProps {
  selected?: Optional<HasDocTypeFields>;
  onChange(val?: HasDocTypeFieldsWithName): void;
  filterBy?: HasDocTypeFields[];
}

export const DocumentTypeSelect = ({ onChange, selected, filterBy }: DocumentTypeSelectProps) => {
  const { projectID } = getCompanyIDAndProjectID();
  const { data, loading } = useQuery<documentTypes, documentTypesVariables>(GET_DOCUMENT_TYPES, {
    fetchPolicy: "no-cache",
    skip: !projectID,
    variables: { projectId: projectID! },
  });

  const selectedId = createDocumentTypeKey(selected);
  const allDocTypes = (data?.project?.documentTypes || []).sort(sortByName);

  const filteredItems = filterBy && filterBy.map((f) => createDocumentTypeKey(f)).filter((f) => f); //filter undefined or null
  const docTypes = allDocTypes
    .filter((docType) => {
      return filteredItems ? filteredItems.includes(createDocumentTypeKey(docType)) : true;
    })
    .filter((docType) => docType.type !== CustomType.Form);

  useEffect(() => {
    if (!loading && data && filterBy && docTypes && !selected) {
      onChange(docTypes[0]);
    }
  }, [data, loading, docTypes, filterBy, selected]);

  const selectedDocType = docTypes.find((docType) => createDocumentTypeKey(docType) === selectedId);

  const docTypeOptions = docTypes.map((d: documentTypes_project_documentTypes) => {
    const key = createDocumentTypeKey(d);
    return (
      <Select.Option key={key} name={d.name} value={key!}>
        {d.name}
      </Select.Option>
    );
  });

  return (
    <>
      {!loading && (
        <Select
          defaultActiveFirstOption={true}
          placeholder="Select a document type"
          onChange={(key) => {
            onChange(docTypes.find((d: HasDocTypeFields) => createDocumentTypeKey(d) === key));
          }}
          style={{ width: "100%" }}
          value={createDocumentTypeKey(selectedDocType as HasDocTypeFields)}
        >
          {docTypeOptions}
        </Select>
      )}
    </>
  );
};
